<template>
<div class="app-container" v-if="bannerData.id">
  <!-- banner -->
  <div class="banner">
    <div class="banner-inner">
      <!-- <img :src="bannerData.picture" class="pos-rl" v-lazy="bannerData.picture" alt=""> -->
      <div class="img pos-rl">
        <img  :src="bannerData.pictureUrl" v-if="bannerData.picture" :onerror="getImage(bannerData)" alt="" />
      </div>
      <div class="banner-box">
        <div class="poster-top">
          <div class="left">
            <h2 @click="goAnchor('#service')">{{bannerData.title}}</h2>
            <div class="intro-text" v-html="bannerData.pictureDescriptionOne"></div>
            <el-button type="primary" 
            @click="gotoLink(bannerData.bannerManagementSon)"
            v-if="bannerData.bannerManagementSon"
            :class="bannerData.bannerManagementSon?'left-btn dynamic':'left-btn'"
            :style="getBtnStyle(bannerData.bannerManagementSon)" 
            >{{bannerData.bannerManagementSon.buttonText}}</el-button>
          </div>
        </div>
        <div class="poster-bottom" v-if="homeDataList[4]">
          <div class="intro-line">
            <div class="icon"></div>
            <ul v-if="homeDataList[4].children">
              <li>
                <div class="ico ico1"></div>
                <div class="title">
                  <h3>{{homeDataList[4].children[0].contentManagement.title}}</h3>
                  <span>{{homeDataList[4].children[0].contentManagement.description}}</span>
                </div>
              </li>
              <li>
                <div class="ico ico2"></div>
                <div class="title">
                  <h3>{{homeDataList[4].children[1].contentManagement.title}}</h3>
                  <span>{{homeDataList[4].children[1].contentManagement.description}}</span>
                </div>
              </li>
              <li>
                <div class="ico ico3"></div>
                <div class="title">
                  <h3>{{homeDataList[4].children[2].contentManagement.title}}</h3>
                  <span>{{homeDataList[4].children[2].contentManagement.description}}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 业务范围 -->
  <div class="research columns" id="service" ref="DOM" v-if="homeDataList[0]">
    <div class="inner">
    <div class="center-header">
      <h3>{{homeDataList[0].name}}</h3>
      <span>{{homeDataList[0].contentManagement.description}}</span>
    </div>
    <div class="research-list">
      <ul>
        <li class="research-list-item" v-for="(item,index) in homeDataList[0].children.slice(0, 8)" :key="item.id">
          <div class="img" @click="gotoService(item,index)">
            <!-- <img :src="item.pictureUrl" @click="gotoService(item,index)" v-lazy="item.pictureUrl" v-if="item.picture" alt="" /> -->
            <!-- <img :src="getImage(item.picture)" v-lazy="()=>getImage(item.picture)" v-if="item.picture" alt=""> -->
            <img  :src="item.pictureUrl" v-if="item.picture" :onerror="getImage(item)" alt="" />
            <img src="../../static/list1.png" v-else alt="" />
          </div>
          <div class="title" @click="gotoService(item,index)">{{item.name}}</div>
          <div class="text" v-if="item.contentManagement" v-html="item.contentManagement.content">
          </div>
        </li>
      </ul>
    </div>
    </div>
  </div>
  <!-- 主要业务 -->
  <!-- <div class="business columns">
    <div class="inner">
      <div class="left">
        <div class="header">
          <h3>{{homeDataList[1].name}}</h3>
        </div>
        <div class="business-list">
          <ul>
            <li v-for="item in homeDataList[1].children" :key="item.id">
              <div class="img">
                <img :src="item.pictureUrl" v-lazy="item.pictureUrl" v-if="item.picture" alt="" />
                <img src="../../static/cdisc.png" v-else alt="" />
              </div>
              <span>{{item.name}}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <img :src="homeDataList[1].pictureUrl" v-lazy="homeDataList[1].pictureUrl" v-if="homeDataList[1].picture" alt="">
      </div>
    </div>
  </div> -->
  <!-- other services -->
  <!-- <div class="services columns">
    <div class="dis-flex">
      <div class="one pos-rl">
        <div class="content">
          <h3>{{homeDataList[2].children[0].name}}</h3>
          <div class="text ft-slim" v-html="homeDataList[2].children[0].contentManagement.content"></div>
        </div>
      </div>
        <div class="two pos-rl">
          <div class="title"><h2 class="txt-rt">{{homeDataList[2].name}}</h2></div>
          <div class="content-box pos-rl">
            <div class="content">
              <h3>{{homeDataList[2].children[1].name}}</h3>
              <div class="text ft-slim" v-html="homeDataList[2].children[1].contentManagement.content"></div>
            </div>
          </div>
        </div>
    </div>
  </div> -->
  <!-- client -->
  <div class="client columns" v-if="homeDataList[3]">
    <div class="client-inner">
      <div class="top dis-flex">
        <div class="left">
         <div class="img">
          <!-- <img :src="homeDataList[3].pictureUrl" v-lazy="homeDataList[3].pictureUrl" v-if="homeDataList[3].picture" alt="" /> -->
          <img  :src="homeDataList[3].pictureUrl" v-if="homeDataList[3].pictureUrl" :onerror="getImage(homeDataList[3])" alt="" />

          <img src="../../static/client.png" v-else alt="">
         </div>
        </div>
        <div class="right">
          <div class="title">{{homeDataList[3].name}}</div>
          <div class="content" v-html="homeDataList[3].contentManagement.content">
          </div>
        </div>
      </div>
      <div class="bottom dis-flex pos-rl">
        <div class="bottom-list" v-for="item in homeDataList[3].children.slice(0, 3)" :key="item.id">
          <h3>{{item.contentManagement.title}}</h3>
          <span>{{item.contentManagement.description}}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- news -->
  <div class="news columns " id="newsTarget" v-if="homeDataList[5]">
    <div class="news-inner">
      <div class="center-header" v-if="homeDataList[5].contentManagement">
        <h3>{{homeDataList[5].contentManagement.title}}</h3>
        <span>{{homeDataList[5].contentManagement.description}}</span>
      </div>
    <div class="news-list">
      <ul class="dis-flex">
        <li v-for="(item,index) in newsList" :key="item.id">
          <div class="pic">
            <!-- <img :src="item.pictureUrl" v-lazy="item.pictureUrl" v-if="item.picture" alt=""> -->
            <img  :src="item.pictureUrl" v-if="item.picture" :onerror="getImage(item)" alt="" />
            <!-- <img :src="item.pictureUrl" v-lazy="item.pictureUrl" v-if="item.picture" alt=""> -->
          </div>
          <div class="content">
            <h4 v-html="item.newsHeadlines"  @click="goToDetail(item,index)"></h4>
            <div class="text ft-slim" v-html="item.content">
            </div>
            <el-button class="read-btn" @click="goToDetail(item,index)">{{$t('common.read')}}</el-button>
          </div>
        </li>
      </ul>
      <div class="load-more">
          <el-button class="load-btn" @click="loadMore" v-if="loadBtnShow">{{$t('common.loadmore')}}</el-button>
      </div>
    </div>
    </div>
  </div>
</div>
</template>

<script>
import Cookies from 'js-cookie'
import router from '@/router'
import {post, del, get, putUrl} from "@/api/http";
import request from "@/utils/request.js";

export default {
  name: 'Home',
  // components: { InfoDialog, UpdatePassword,TagsView, sideMenu},
  data() {
    return {
      homeDataList:[],
      bannerData:{},
      buttonColor:'',
      newsList:[],
      newsPage:1,
      loadBtnShow:true,
      imgUrl:'',
      listenFlag:true,
    }
  },
  watch:{
     bannerData:{
      handler(newName, oldName) {
        console.log(newName);
          // 屏幕内容短时自动加载新闻
          this.$nextTick(()=>{
            let newsTop = document.getElementById('newsTarget').offsetTop
            if(newsTop<=1000){
              this.getList()
            }
          })
      },
      deep: true
    },

  },
  methods: {
    getData(id){
      post("/publicController/publicData?id=1",{id:id}).then(res => {
          if (res.code == 1) {
              this.homeDataList=res.data.business
              this.bannerData = res.data.homeIntroduction[0]
          } else {
              this.$message.error(res.data);
          }
      });
    },
    getBgStyle(item){
      if (item.picture) {
        return {
        backgroundImage:
          'url(' + item.picture + ') ',
        }
      } else {
        return {
        backgroundImage:
          'url(' +
          require('../../static/banner.png') +
          ') ',
        }
      }
    },
    // banner按钮
    getBtnStyle(data){
      if (data) {
        return {
          // backgroundColor:data.buttonColor,
          // borderColor:data.buttonColor,
          // color:data.buttonTextColor,
          '--btnbg':data.buttonColor||'',
          '--btnftcolor':data.buttonTextColor||'',
          width:data.buttonSizeOne +'px',
          height:data.buttonSizeTwo+'px',
          fontSize:data.buttonTextSize+'px',
          boxShadow:'0 37px 100px'+' '+ this.hexToRgba(data.buttonColor,0.6),
          '--btnhoverbg':data.mousePassButtonColor||'',
          '--btnhoverftcolor':data.mousePassTextColor||'',
        }
      } else {
        return {}
      }
    },
    // 颜色六进制转rgb
    hexToRgba(hex, opacity) {
      if(hex && opacity){
        return "rgba(" + parseInt("0x" + hex.slice(1, 3)) + "," + parseInt("0x" + hex.slice(3, 5)) + "," + parseInt("0x" + hex.slice(5, 7)) + "," + opacity + ")";
      }else{
        return ''
      }
    },
    // banner按钮跳转
    gotoLink(btn){
      let blank=''
      btn.openMode==1? blank = "_blank":blank= '_self'
      window.open(btn.link,blank)
    },
    // 新闻
    getList(size){
      let page = size || this.newsPage
      post("pressCenter/getPage",{
        page:page,
        limit:4,
      }).then(res => {
          if (res.code == 1) {
              // this.newsList=res.data.data
              this.newsList=this.newsList.concat(res.data.data)
              if(res.data.count<=this.newsList.length){
                this.loadBtnShow=false
              }
          } else {
              this.$message.error(res.data);
          }
      });
    },
    // 服务详情
    gotoService(data,index){
      console.log(data);
      data.index = index
      this.$router.push({ path: "/service", query: {id:data.id,parentId:data.parentId,index:data.index} });
    },
    // 新闻详情
    goToDetail(data,index){
      data.page = 1
      data.index = index
      this.$router.push({ path: "/news-detail", query: data });
    },
    loadMore(){
      // 新闻没有自动加载，点击请求第一页
      if(this.newsList.length==0){
        this.getList()
      }else{
        this.getList(this.newsPage+1)
      }
    },
    // 获取图片地址
    // async getImage(row){
    //   if(row.picture && !row.pictureUrl){
    //     let res = await get("/file/oss-url/"+row.picture)
    //     row.pictureUrl = res.data
    //     row.onerror = null
    //   }
    // },
    // getImage(row,list){
    //   setTimeout(function () {
    //     if(!row.pictureUrl){
    //       get("/file/oss-url/"+row.picture).then(res=>{
    //         row.pictureUrl = res.data
    //       })
    //     }
    //   }.bind(this), 1000);
    // },
   getImage(row) {
      setTimeout(function () {
        if (!row.pictureUrl){
          request({
            url: "/file/download",
            method: "POST",
            data: {
                id:row.picture
            },
            isDownLoadFile: true,
            isParams: true,
            responseType: "blob"
          }).then(res=>{
            const blob = new Blob([res], {
                type:"application/pdf"
            });
            const linkNode = document.createElement("a");
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            row.pictureUrl =  linkNode.href 
          })
        }
      }.bind(this), 1000);
    },

    // 滚动加载
    handleScroll () {
      if(this.bannerData.id){
        this.$nextTick(()=>{
          let newsTop = document.getElementById('newsTarget').offsetTop
          if(this.listenFlag && newsTop){
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            if(scrollTop>=(newsTop-500)){
              this.getList()
              this.listenFlag=false
            }
          }
        })
      }
    },
    // 锚点定位
    goAnchor(selector) {
      // console.log(selector);
        setTimeout(() => {
          this.$nextTick(() => {
            let scroll = this.$el.querySelector(selector)
            document.body.scrollTop = scroll.scrollHeight
          })
        }, 100)
    }
  },
  mounted() {
    this.getData(1)
    // 定位
    this.$bus.on("anchor",(path)=>{
      this.goAnchor(path)
    });
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动

    
  },
  beforeDestroy () {
      window.removeEventListener('scroll', this.handleScroll)
      this.$bus.off("anchor")
  },
}
</script>
<style lang="scss" scoped>

.inner{
  width: 1620px;
  margin:0 auto;
}
.banner-inner{
  max-width: 100%;
  min-width: 1920px;
  margin:0 auto;
  position: relative;
}
.columns{
  padding:100px 0
}
// banner
.banner{
  background-color: #F2F7FD;
  // background-image: url('../../static/banner.png');
  background-size:cover;
  position: relative;
  height: 1045px;
  display: flex;
  justify-content: center;
  .img{
    overflow: hidden;
    width:100%;
    height: 1045px;
  }
  img{
    max-width: 100% !important;
  }
.banner-box{
  z-index: 10;
  position: absolute;
  top: 0;
  width: 100%;
}
.poster-top{
  height: 900px;
  display: flex;
}
.left{
  position: absolute;
  left: 250px;
  width:32%;
  top:230px;
  h2{
    font-weight: 800;
    color:#333333;
    font-size: 76px;
  }
  .intro-text{
    color:#9e9e9e;
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    margin-top:20px;
  }
  .left-btn{
    background-color: #0063E0;
    border-color:#0063E0;
    height: 80px;
    width:280px;
    min-width: 120px;
    min-height: 40px;
    border-radius: 16px;
    color:#fff;
    font-size: 24px;
    font-weight: bold;
    margin-top:70px;
    // box-shadow: 0 37px 100px 0 rgba(0, 99, 224 , 0.6)
  }
  .left-btn.dynamic{
    background-color:var(--btnbg);
    border-color:var(--btnbg);
    color:var(--btnftcolor);
  }
  .left-btn.dynamic:hover{
    background-color:#fff;
    background-color:var(--btnhoverbg);
    border-color:var(--btnhoverbg);
    color:var(--btnhoverftcolor);
  }
}
}
.poster-bottom{
  display: flex;
  height: 80px;
  justify-content: flex-end;
  .intro-line{
    width:60%;
    background-color: #ffffff;
    height: 150px;
    position: relative;
    bottom: -53px;
    border-radius: 16px 0 0 16px;
    background-size:cover;
    .icon{
      width:60px;
      height: 60px;
      border-radius: 30px;
      background-color: #0E63DC;
      position: absolute;
      left:-30px;
      top:60px;
      background-image: url(../../static/microscope.png);
      background-repeat: no-repeat;
      background-position: center;
    }
    ul{
      display: flex;
      flex: 1;
      height: 100%;
      align-items: center;
      margin-left: 70px;
      margin-right: 20px;
      justify-content: space-around;
    }
    li{
      // margin:0 20px;
      // width:33%;
      margin-right: 1%;
      font-size: 22px;
      display: flex;
    }
    li .ico{
      width:50px;
      height: 50px;
      margin-top: 18px;
    }
    .ico1{
      background: url(../../static/team.png) no-repeat center;
    }
    .ico2{
      background: url(../../static/bulb.png) no-repeat center;
    }
    .ico3{
      background: url(../../static/experiment.png) no-repeat center;
    }
    li h3{
      line-height: 80px;
      height: 80px;
      overflow: hidden;
      word-break: break-all;
    }
    li span{
      font-weight: 300;
      color:#333
    }
  }
}

.research{
  // height: 800px;
  margin-top: 70px;
  background: url('../../static/bg3.png') no-repeat ;
  background-size:cover;
}
.center-header{
  text-align: center;
    h3{
      font-size: 48px;
      line-height: 90px;
    }
    span{
      font-size: 22px;
      font-weight: 300;
    }
}
.research-list ul{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-top:50px;
  .research-list-item{
    width:20%;
    padding: 50px 40px;
    overflow: hidden;
    .img{
      margin:20px 0 60px;
      cursor: pointer;
      img{
        box-shadow: 20px 27px 32px 0 rgba(0,132,231, 0.1);
        border-radius: 24px;
        width:136px;
        height: 136px;
      }
    }
    .title{
      line-height: 1.5;
      margin-block: 10px;
      font-weight: bold;
      font-size: 22px;
      cursor: pointer;
    }
    .text{
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      height: 52px;
      overflow: hidden;
    }
  }
}
.business{
  background: url('../../static/bg1.png') no-repeat ;
  // background-size:cover;
  height: 1000px;
  .inner{
    display: flex;
    justify-content: space-between;
  }
  .header{
    font-size: 48px;
    line-height: 100px;
    text-align: left;
    margin-bottom: 150px;
  }
  .business-list{
    font-weight: bold;
    font-size: 22px;
    li{
      height: 85px;
    }
    .img{
      display: inline-block;
      margin-right: 20px;
    }
    img{
      vertical-align: -10px;
      display: inline;
    }
  }
  .right{
    position: absolute;
    right: 150px;
    height: 970px;
    width:50%;
    // background: url('../../static/man.png') ;
    background-size:cover;
  }
}
.services{
  background: url('../../static/bg2.png') no-repeat ;
  background-size:cover;
  height: 943px;
  padding-top: 292px;
  .one{
    width:50%;
    background: url('../../static/one.png') no-repeat ;
    background-size:contain;
    height: 570px;
    margin-left: 60px;
    .content{
      position: absolute;
      width: 410px;
      right: 22px;
      color: #ffffff;
      top: 140px
    }
  }
  .two{
    width:50%;
  }
  .title h2{
    margin-right: 150px;
    font-size: 48px;
  }
  .content-box{
    top:120px;
    background: url('../../static/two.png') no-repeat left bottom;
    background-size:contain;
    height: 610px;
    .content{
      position: absolute;
      width: 390px;
      right: 80px;
      color: #ffffff;
      top: 180px;
    }
  }
    h3{
      font-size: 42px;
      font-weight: bold;
      line-height: 90px;
      margin-bottom: 10px;
    }
    .text{
      line-height: 40px;
    }
}
.client{
  background-color: #F4F9FE;
  // padding:200px 80px 100px;
  background-image: url('../../static/client2.png');
  background-repeat: no-repeat;
  background-size: cover;
  .client-inner{
  padding:200px 80px 100px;
    // width:1800px;
    width: 1620px;
    margin:0 auto;
  }
  .left{
    // width:40%;
    .img{
      width: 730px;
      height: 850px;
    }
    img{
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .right{
    padding:50px 50px 0 150px;
    .title{
      font-size: 74px;
      font-weight: bold;
      line-height: 160px;
    }
    span{
      color:#0063E0;
    }
    .content{
      line-height: 50px;
      font-size: 24px;
      p{
        margin-bottom: 30px;
      }
    }
  }
  .bottom{
    background-color: #ffffff;
    width:80%;
    min-width: 600px;
    border-radius: 30px;
    padding:20px 40px;
    margin:0 auto 0;
    top:-120px;
    text-align: center;
    justify-content: space-between;
    >div{
      width:30%
    }
    h3{
      font-size: 42px;
      line-height: 70px;
      height: 70px;
      overflow: hidden;
    }
    span{
      color:#9e9e9e;
      line-height: 56px;
    }
  }
}
.news{
  background: -webkit-linear-gradient(left,#EFF6FD -6%,#ffffff,#ffffff);
  .news-inner{
    padding:0 60px;
    margin:0 auto;
    width: 1620px;
  }
}
.news-list ul {
  margin-top: 80px;
  flex-wrap: wrap;
  li{
    width:23.5%;
    box-shadow: 0px 20px 27px 0px rgb( 246, 246, 246 );
    border-radius: 20px;
    overflow: hidden;
    margin-right: 2%;
    margin-bottom: 20px;
    .pic{
      border-radius: 20px;
      overflow: hidden;
      width: 100%;
      height: 245px;
      background-color: #F1F3F4;
      img{
        display: block;
        width:100%;
      }
    }
    .content{
      padding:20px;
    }
    h4{
      margin-bottom: 20px;
      line-height: 28px;
      height: 56px;
      cursor: pointer;
      width: 100%;
      overflow : hidden;
      display: -webkit-box;    /*弹性伸缩盒子*/
      -webkit-box-orient: vertical; /*子元素垂直排列*/
      -webkit-line-clamp: 2;   /*可以显示的行数，超出部分用...表示*/
      text-overflow: ellipsis; 
    }
    .text{
      font-size: 16px;
      line-height: 190%;
      height: 146px;
      margin-bottom: 20px;
      width: 100%;
      overflow : hidden;
      display: -webkit-box;    /*弹性伸缩盒子*/
      -webkit-box-orient: vertical; /*子元素垂直排列*/
      -webkit-line-clamp: 5;   /*可以显示的行数，超出部分用...表示*/
      text-overflow: ellipsis; 
    }
    .read-btn{
      color:#fc7700;
      background-color: #FFF5EC;
      border-color: #FFF5EC;
      border-radius: 25px;
      padding:17px 45px;
      margin:20px 0;
    }
  }
  li:nth-of-type(4n){
    margin-right: 0;
  }
}
.load-more{
  margin:120px auto;
  text-align: center;
  .load-btn{
    background-color: #ffffff;
    border:3px solid #3F89F3;
    border-radius: 20px;
    height: 80px;
    width: 250px;
    color:#3F89F3;
    font-size: 20px;
    font-weight: bold;
  }
}

</style>
